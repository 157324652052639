<template>
  <b-container id="devis" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <router-link :to="prev">Config</router-link> <span class='decoration'>></span> <router-link to="/list">{{list_label}}</router-link> <span class='decoration'>></span> <router-link to="/options">Accessoires</router-link> <span class='decoration'>></span> <router-link to="/informations">Données</router-link> <span class='decoration'>></span> <span class='selected'>Devis PDF</span></p>
    </div>
    <b-row class="justify-content-center" v-if="!shortcut && !rexel && !leroymerlin && !yesss">
      <b-col class="subtitle"><h3>Votre devis vous a été envoyé par mail à l’adresse renseignée précédemment.</h3></b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="9" class="thanks">
        <div class="inner-shadow-box">
          <p>S&amp;P France vous remercie d’avoir utilisé ESTIMAIR.</p>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="yesss" class="justify-content-center">
        <b-col cols="12" lg="3" class="new_devis">
          <div class="shadow-box">
            <a href="#" @click="yess_add_cart($event)" style="color:black;">{{addBasketRexelTag}}</a>
          </div>
        </b-col>
    </b-row>
    <b-row v-if="rexel" class="justify-content-center">
        <b-col cols="12" lg="3" class="new_devis">
          <div class="shadow-box">
            <a href="#" @click="rexel_add_cart($event)" style="color:black;">Ajouter au panier</a>
          </div>
        </b-col>
    </b-row>
    <b-row v-if="leroymerlin" class="justify-content-center">
        <b-col cols="12" lg="3" class="new_devis">
          <div class="shadow-box">
            <a href="#" @click="leroymerlin_add_cart($event)" style="color:black;">Ajouter au panier</a>
          </div>
        </b-col>
    </b-row>
    <b-row v-if="sonepar" class="justify-content-center">
        <b-col cols="12" lg="3" class="new_devis">
          <form method="POST" target="_blank" name="Interface" id="interface_sonepar">
            <input type="HIDDEN" name="NumCli" id="NumCli" value="">
            <input type="HIDDEN" name="NomUtil" id="NomUtil" value="">
            <input type="HIDDEN" name="FlagRAZ" id="FlagRAZ" value="0">
            <input type="HIDDEN" name="LstRefQte" id="LstRefQte" value="LEG 401211|1|LEG 401331|1|LEG 406773|3|LEG 406774|3|LEG 407901|4|LEG 411505|1|LEG 420013|1">
            <input type="HIDDEN" name="CampaignName" id="CampaignName" value="LEGBasketTransfer">
            <input type="HIDDEN" name="FlagLIV" id="FlagLIV" value="LIV">
            <input type="HIDDEN" name="RefCht" id="RefCht" value="Nomdemonetude">
            <input type="HIDDEN" name="RefCde" id="RefCde" value="nomdutableau">
            <input type="HIDDEN" name="DtExp" id="DtExp" value="01/04/2021">
          </form>
          <div class="shadow-box">
            <a href="#" @click="sonepar_add_cart($event)" style="color:black;">Ajouter au panier</a>
          </div>
        </b-col>
    </b-row>
    <b-row v-if="excel" class="justify-content-center">
        <b-col cols="12" lg="3" class="new_devis">
          <div class="shadow-box">
            <a href="#" @click="excel_export($event)" style="color:black;">Générer Excel</a>
          </div>
        </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="3" class="new_devis">
        <div class="shadow-box">
          <router-link to="/">
            <p>Générer un nouveau devis</p>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="shortcut">
      <b-col>
        <object
        :data='$api_url+id_file+".pdf"'
        type="application/pdf"
        width="100%"
        height="1200px"
      >

        <iframe
          :src='$api_url+id_file+".pdf"'
          width="100%"
          height="1200px"
        >
        <p>This browser does not support PDF!</p>
        </iframe>

      </object>
        <!--<iframe :src='"http://docs.google.com/gview?url="+$api_url+id_file+".pdf&embedded=true"' style="width: 50%;height: 1150px;" frameborder="0"></iframe>-->
      </b-col>
    </b-row>
      <b-row class="footer align-items-center no-gutters">
        <b-col cols="1">
          <b-button @click="back($event)">
            <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
          </b-button>
        </b-col>
        <b-col cols="10">
        </b-col>
        <b-col cols="1">
        </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Devis',
  data() {
      return {
        id_file:'',
        show: true,
        list_label: 'Choix VMC',
        classic: true,
        solerpalau: false,
        sonepar: false,
        shortcut: false,
        rexel:false,
        yesss:false,
        leroymerlin:false,
        excel:false,
        addBasketRexelTag: 'Ajouter au Panier',
      }
    },
    methods: {
      next(evt){
        evt.preventDefault();
        this.$router.push('/');
      },
      back(evt){
        evt.preventDefault();
        this.$store.dispatch('devis/setPDF',{});
        this.$router.go(-1);
      },
      leroymerlin_add_cart(event){
        event.preventDefault();
        event.stopPropagation();
        console.log("ok");
        var pdf_content = JSON.parse(this.$store.state.devis.devis['pdf']['content']);
        console.log(pdf_content);
      },
      yess_add_cart(event){
        event.preventDefault();
        event.stopPropagation();
        var devisItems = [];
        var pdf_content = JSON.parse(this.$store.state.devis.devis['pdf']['content']);
        pdf_content['content']['rows'].forEach(element => {
          element.row.forEach(product => {
            var tmpQte = product.qte=="" ? 1 : parseInt(product.qte)
            devisItems.push({
              manufacturer: 'SP',
              quantity: tmpQte,
              reference:product.lidic,
              ean: product.ean
            });
          });
        });
        const baseURI = this.$api_url+'addBasket';
        this.$http.post(baseURI,{'basket':devisItems,'env':this.$store.state.devis.devis.env})
        .then((result) => {
          if(result){
            console.log(result.data);
            if(result.data.dataBasket.status_message=="success"){
              window.open(result.data.dataBasket.data.redirect_uri, '_blank', 'noreferrer');
            }
          }
        });

      },
      rexel_add_cart(event){
        event.preventDefault();
        event.stopPropagation();
        console.log("ok");
        var pdf_content = JSON.parse(this.$store.state.devis.devis['pdf']['content']);
        console.log(pdf_content);
        var rexelData={
          totalProductCount:0,
          ConfiguratorName:'Estimair',
          products:[]
        }
        var totalProductCount = 0;
        pdf_content['content']['rows'].forEach(element => {
          var tmpProduct = [];
          element.row.forEach(product => {
            var tmpQte = product.qte=="" ? 1 : parseInt(product.qte)
            totalProductCount +=tmpQte;
            tmpProduct.push({
              productCode: product.lidic,
              quantity: tmpQte,
              ean: product.ean
            });
          });
          rexelData.products.push({
              title:element.libelle,
              id:element.libelle,
              product:tmpProduct
          });
        });
        rexelData.totalProductCount = totalProductCount;
        console.log(rexelData);
        parent.postMessage(JSON.stringify(rexelData),"https://www.rexel.fr");
      },
      sonepar_add_cart(event){
        event.preventDefault();
        event.stopPropagation();
        var pdf_content = JSON.parse(this.$store.state.devis.devis['pdf']['content']);
        var listrefgenerated = "";

        pdf_content['content']['rows'].forEach(element => {
          element.row.forEach(product => {
            listrefgenerated+= "S&P "+product.ean+"|"+product.qte+"|";
          });
        });
        listrefgenerated = listrefgenerated.slice(0, -1);

        var form = document.getElementById('interface_sonepar');
        var RefCht = document.getElementById('RefCht');
        RefCht.value = "S&P Devis";

        var RefCde = document.getElementById('RefCde');
        RefCde.value = "S&P Devis Panier";

        var DtExp = document.getElementById('DtExp');
        DtExp.value = pdf_content.date_creation;

        var LstRefQte = document.getElementById('LstRefQte');
        LstRefQte.value = listrefgenerated;

        form.action=this.$url_api_client;
        form.submit();
      },
      excel_export(event){
        event.preventDefault();
        event.stopPropagation();

        const baseURI = this.$api_url+'export_devis_excel';
        this.$http.post(baseURI,{"devis_id":this.id_file})
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data.split("\n").slice(1).join("\n").replaceAll(",",";")]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export_devis.csv');
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    computed: {
      prev() {
        let res = '/'
        console.log("Back");
        switch(this.$store.state.devis.devis['type']){
          case "individuel":
            res = '/individuel';
          break;
          case 'collectif':
            res = '/collectif';
          break;
          case 'desenfumage':
            res = '/desenfumage';
          break;
          default:
          break;
        }
        return res;
      },
      form() {
        let state = {};
        if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['content']!=undefined){
          state = this.$store.state.devis.devis;
        }
        return state;
      },
      cssProps() {
        return {
          '--maincolor': this.$maincolor
        }
      },
    },
    mounted(){
      window.scrollTo(0, 0);
      console.log(this.$store.state.devis.devis);
      this.id_file = this.$store.state.devis.devis['pdf']['id'];
      this.solerpalau = (/@solerpalau.com\s*$/.test(this.$store.state.devis.devis['informations']['mail'])) || (/@solerpalau.com\s*$/.test(this.$store.state.devis.devis['informations']['mail_demandeur'])) || this.$path=='/sonepar/' || this.$dev || this.$path=='/rexel/';
      this.shortcut = this.$path=='/sonepar/' || this.$dev || this.$path=='/cged/' || this.solerpalau;
      this.sonepar = this.$path=='/sonepar/' || this.$path=='/cged/';
      this.excel = this.$dev;
      this.rexel = this.$path=='/rexel/';
      this.yesss = this.$path=='/yes/';
      console.log(this.$store.state.devis);
      if(this.yesss && this.$store.state.devis.devis.env=="intrayesss"){
        this.addBasketRexelTag = 'Ajouter au devis';
      }
      this.leroymerlin = this.$path=='/leroy-merlin/';
      switch(this.$store.state.devis.devis['type']){
          case 'desenfumage':
            this.list_label = 'Choix Volet';
            this.classic = false;
          break;
          default:
            this.list_label = 'Choix VMC';
            this.classic = true;
          break;
      }
    }
}
</script>

<style lang="scss">
#devis{
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;
  .row{ text-align: center;}
  .subtitle{
    margin-bottom:3em;
    h3{
      font-size: 2.5em;
    }
  }

  .thanks div{
    margin-bottom:2em;
    font-size: 1.5em;
    color: #575F6B;
    border-radius: 1.5em;
    padding:1em 1em;
    p{
      margin:0;
      padding: 0 4em;
    }
  }

  .new_devis div{
    margin-bottom:2em;
    font-size: 1.5em;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    border-radius: 2em;
    padding:0.5em 0em;
    a{
      &:hover{
        text-decoration: none;
      }
      p{
       margin:0;
       color: black;
     }
    }
  }
}
</style>
