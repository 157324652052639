<template>
  <b-container id="desenfumage" fluid="xl" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <span class='selected'>Config</span> <span class='decoration'>></span> Choix Volets <span class='decoration'>></span> Accessoires <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3>Sélectionnez la configuration de votre chantier</h3>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="main_content">
    <b-row align-h="center">
      <b-col cols="12" md="6" lg="3">
        <div class="content-block shadow-box">
          <div class='header-form row align-items-center'>
            <b-col cols="8">
              <p><span>Architecture</span><br>du batiment</p>
            </b-col>
            <b-col cols="4">
              <b-img src="../assets/piece-icon.png" />
            </b-col>
          </div>
          <div class="content-form spinner">
            <b-row>
              <b-col cols="8">
                <label for="sdb">Nb. de Niveau (RDC inclus)</label>
              </b-col>
              <b-col cols="3">
                <b-form-spinbutton inline id="sdb" v-model="form.niveau" min="1" max="16" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
            <label for="wc">Nb. de Colonne Basse (VB)</label>
          </b-col>
          <b-col cols="3">
            <b-form-spinbutton inline id="wc" v-model="form.vb" min="1" size="sm" @change="setSdb" @input="maxVB" class="small-version" v-if="renderComponent"></b-form-spinbutton>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <label for="sdb_wc">Nb. de Colonne Haute (VH)</label>
          </b-col>
          <b-col cols="3">
            <b-form-spinbutton inline id="sdb_wc" v-model="form.vh" min="1" max='12' size="sm" @change="setSdb" v-if="renderComponentVH" @input="maxVH" class="small-version"></b-form-spinbutton>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <label for="eau">Nb. de cage d'escalier</label>
          </b-col>
          <b-col cols="3">
            <b-form-spinbutton inline id="eau" v-model="form.escalier" min="1" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <label for="ascenseur">Nb. d'ascenseur</label>
          </b-col>
          <b-col cols="3">
            <b-form-spinbutton inline id="ascenseur" v-model="form.ascenseur" min="0" size="sm" @change="setSdb" class="small-version"></b-form-spinbutton>
          </b-col>
        </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <div class="content-block shadow-box" style="padding: 1em 1em 1.5em 1em;">
          <div class='header-form row align-items-center'>
            <b-col cols="8">
              <p><span>Longueur</span><br>de couloir<br>en mètres</p>
            </b-col>
            <b-col cols="4">
              <b-img src="../assets/size-icon.png" />
            </b-col>
          </div>
          <div class="content-form roundButton oneline">
            <b-button @click.prevent="setCouloir(0)" class='couloir couloir_0'>-10</b-button>
            <b-button @click.prevent="setCouloir(1)" class='couloir couloir_1' style="text-transform:none">10 à 20</b-button>
            <b-button @click.prevent="setCouloir(2)" class='couloir couloir_2' style="text-transform:none">+30</b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <div class="content-block shadow-box">
          <div class='header-form row align-items-center'>
            <b-col cols="8">
              <p><span>Ventilation Haute</span><br>RDC</p>
            </b-col>
            <b-col cols="4">
              <b-img src="../assets/vmc-icon.png" />
            </b-col>
          </div>
          <div class="content-form checkBox">
            <b-row>
              <b-col>
                <b-button @click.prevent="setVentilation('H')" class='ventilation ventilation_h'>Plafond</b-button>
              </b-col>
              <b-col>
                <b-button @click.prevent="setVentilation('V')" class='ventilation ventilation_v'>Mural</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer align-items-end no-gutters">
      <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
        <b-button @click="$router.go(-1)">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2" class="content inner-shadow-box">
        <div class="content-block" style="background:none;">
          <h5 style="text-align:left;">Exemple d'installation:</h5>
          <img :src='"/img/"+$path+"synoptique desenfumage.png"' style="width:auto;max-height:400px;max-width:100%"/>
          <p>Au dela de 16 étages, <strong>contactez votre conseiller</strong>.</p>
        </div>
      </b-col>
      <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
        <b-button type="submit" class="next" :disabled="!validate">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
    </b-row>
    </b-form>
    <b-card class="mt-3" v-if="debugging" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </b-container>
</template>

<script>

export default {
  name: 'Desenfumage',
  data() {
      return {
        renderComponent:true,
        renderComponentVH:true,
        debugging:false,
        validate: false,
        ventilation: { vertical:false, horizontal:false},
        show: true,
      }
    },
    methods: {
      maxVB(){
        var maxVB = Math.floor((32 - (this.form.niveau*this.form.vh)) / this.form.niveau);
        console.log(maxVB);
        if(this.form.vb > maxVB){
          this.form.vb = maxVB;
          this.renderComponent = false;

          this.$nextTick(() => {
            // Add the component back in
            this.renderComponent = true;
          });
        }

      },
      maxVH(){
        var maxVH = Math.floor((32 - (this.form.niveau*this.form.vb)) / this.form.niveau) > 12 ? 12 : Math.floor((32 - (this.form.niveau*this.form.vb)) / this.form.niveau);
        console.log(maxVH);
        if(this.form.vh > maxVH){
          this.form.vh = maxVH;
          this.renderComponentVH = false;

          this.$nextTick(() => {
            // Add the component back in
            this.renderComponentVH = true;
          });
        }

      },
      onSubmit(evt) {
        evt.preventDefault();
        this.form.type = "desenfumage";
        this.$store.dispatch('devis/setRequest',this.form);
        const baseURI = this.$api_url+'products'
        this.$http.post(baseURI,{"request":this.form})
        .then((result) => {
          console.log("saved");
          console.log(this.$store.state.devis.devis['request']);
          this.$store.dispatch('products/setProducts',result.data);
          this.$router.push('list');
        })

      },
      onReset(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      setCouloir(couloir){
        let x = document.getElementsByClassName("couloir");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(couloir){
          case 0:
            document.getElementsByClassName("couloir_0")[0].classList.add('active');
          break;
          case 1:
            document.getElementsByClassName("couloir_1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("couloir_2")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.couloir = couloir;
        this.form = res;
      },
      setSdb(){
        console.log(this.form);
        let res = this.form;
        this.form = res;
      },
      setVentilation(ventilation){
        let x = document.getElementsByClassName("ventilation");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(ventilation){
          case 'V':
            document.getElementsByClassName("ventilation_v")[0].classList.add('active');
          break;
          case 'H':
            document.getElementsByClassName("ventilation_h")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.ventilation = ventilation;
        this.form = res;
      },
    },
    computed:{
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      form: {
        get:function() {
          let state = {};
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
            state = this.$store.state.devis.devis['request'];
          }else{
            state['ventilation'] = state['couloir'] = null;
            state['ascenseur'] =  0;
            state['niveau'] = state['vh'] = state['vb'] = state['escalier'] = 1;

          }
          return state;
        },
        set:function(){
          console.log(this.form);
          let validate = this.form.ventilation!=null && this.form.couloir!=null;
          this.validate = validate;
        }
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log("mouted");
      if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
        console.log("ok");
        let state = this.$store.state.devis.devis['request'];
        console.log(state);
        switch(state['ventilation']){
          case 'H':
            document.getElementsByClassName("ventilation_h")[0].classList.add('active');
          break;
          case 'V':
            document.getElementsByClassName("ventilation_v")[0].classList.add('active');
          break;
        }

        switch(state['couloir']){
          case 0:
            document.getElementsByClassName("couloir_0")[0].classList.add('active');
          break;
          case 1:
            document.getElementsByClassName("couloir_1")[0].classList.add('active');
          break;
          case 2:
            document.getElementsByClassName("couloir_2")[0].classList.add('active');
          break;
        }
      }
    }
}
</script>

<style lang="scss">
#desenfumage{
  //padding: 3em 3em 0;
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;

  h3{
    margin-bottom:2em;
  }
  @media (max-width: 576px) {
      padding:3em 0 0;
  }
  .row { margin-bottom: 2em;
    @media (max-width: 576px) {
        margin-bottom: 0;
    }
  }

  .main_content {
    .col-12 div.content-block {
      .content-form{

        &.switchBox{
          padding-top: 1em;
          padding-bottom: 1em;
          .switch-label{
            font-size: 1.5em;
            text-align:left;

            &.active{
              color:var(--maincolor);
            }
          }
        }
        text-align: center;

        .custom-control-label {
          color: #666;
          text-align: left;
          font-size: 1.5em;
        }

        .custom-switch  {
          //padding-left: 3.25rem;
          .custom-control-label::before {
              //left: -2.25rem;
              //width: 1.75rem;
              pointer-events: all;
              border-radius: 1.5rem;
              padding: 0.9rem 1.75rem;
              background: transparent !important;
              box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
              border: 0px;
              top: 0.3rem
          }

          .custom-control-label::after {
              //top: calc(.25rem + 2px);
              //left: calc(-2.25rem + 2px);
              width: calc(1.9rem - 10px);
              height: calc(1.9rem - 10px);
              background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              border-radius: 2.5rem;
              top: 0.55rem;
              left:calc(-2.25rem + 5px);
              transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
            background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
            box-shadow: none;
          }

          .custom-control-input:checked~.custom-control-label::after {
              background: white;
              transform: translateX(1.6rem);
          }
        }

        &.checkBox{
          margin-top: 5.5em;
          border-radius:1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          button{
            box-shadow: none;
            background: transparent !important;
            border:0px;
            color: #666;
            margin:1em 1em;
            padding:1em;
            text-transform: none;
            width: 81%;

            &.active{
              color: var(--maincolor);
              box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            }
          }
        }
        &.spinner{
          text-align: left;
          .row { margin-bottom: 0em;}
          .b-form-spinbutton{
            &.small-version{
              .btn.btn-sm {
                &:first-of-type{
                  margin-right:0;
                }
                &:last-of-type{
                  margin-left:0;
                }
              }
              output{
                padding:0;
              }
            }
          margin-bottom:1em;
          border-radius:2em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          .btn.btn-sm {
            padding:0;
            color: #666;
          }
        }
        }

        &.roundButton {
          &.labelline{
              padding-top: 1.5em;
              .carre.active,.rectangle.active,.forme_en_l.active { color: var(--maincolor); }
              .forme_c{
                &.active span{background: var(--maincolor);}
                span{
                  width:20px;
                  height:20px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_r {
                &.active span{background: var(--maincolor);}
                span{
                  width:15px;
                  height:28px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_l {
                &.active span{background: var(--maincolor);}
                span{
                  width:17px;
                  height:33px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }
          }
          &.oneline{
            padding-top: 3em;
            button{
              font-size:1em;
              margin-left: 1em;
              margin-right: 1em;
            }
          }
          button{
            width: 60px;
            height: 60px;
            padding: 7px 6px;
            border-radius: 50%;
            font-size: 2em;
            text-align: center;
            color: #666666;
            background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
            border: 0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
            margin: 11px 10px;
            &.active{
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              color:var(--maincolor);
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
</style>
