<template>
  <b-container id="tertiaire" fluid="xl" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <span class='selected'>Config</span> <span class='decoration'>></span> Choix VMC <span class='decoration'>></span> Accessoires <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3>Sélectionnez la configuration de votre chantier</h3>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class='main_content'>
      <b-row>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Type</span><br> de Ventilation</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/flux-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setFluxType('S')" class="flux_type flux_type_S">Simple</b-button>
              <b-button @click.prevent="setFluxType('D')" class="flux_type flux_type_D">Double</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="show_DoubleFlux">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Préchauffage</span><br><br></p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/classement_feu.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setVersion('S')" class="version_type version_type_S" style="font-size:0.6em;">Standard</b-button>
              <b-button @click.prevent="setVersion('Elec')" class="version_type version_type_Elec" style="font-size:0.6em;">Batterie Elec.</b-button>
              <b-button @click.prevent="setVersion('Eau')" class="version_type version_type_Eau" style="font-size:0.6em;">Batterie Eau</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="show_SimpleFlux">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Type</span><br> de classement au feu</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/classement_feu.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setAggrement('N')" class="aggrement_type aggrement_type_n">Non Agrée</b-button>
              <b-button @click.prevent="setAggrement('400')" class="aggrement_type aggrement_type_400">400°C 1/2H</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="!hide_Alimentation">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center'>
              <b-col cols="8">
                <p><span>Alimentation</span><br><br></p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/tension-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setTension('M')" class='tension tension_m'>Mono phase</b-button>
              <b-button @click.prevent="setTension('T')" class='tension tension_t'>Tri phase</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3"  v-if="!hide_aspi">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Type</span><br>d'aspiration'</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/aspiration-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setAspiration('L')" class='aspiration aspiration_l' v-if="!hide_type_aspi_ligne">Ligne</b-button>
              <b-button @click.prevent="setAspiration('9')" class='aspiration aspiration_9'>90°</b-button>
              <b-button @click.prevent="setAspiration('D')" class='aspiration aspiration_d'>Double</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3"  v-if="!hide_exigeance">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Exigences</span><br>du batiment</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/reglementation-icon.png" />
              </b-col>
            </div>
            <div class="content-form checkBox">
              <b-row>
                <b-col>
                  <b-button @click.prevent="setReglementation('E')" class='reglementation reglementation_e'>Existant<br> &nbsp;</b-button>
                </b-col>
                <b-col>
                  <b-button @click.prevent="setReglementation('N')" class='reglementation reglementation_n' style="font-size: 0.8em;">Construction<br>Neuve RT 2012</b-button>
                </b-col>
              </b-row>
            </div>
        </div>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="!hide_entree_air">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Type</span><br>d'entrée d'air</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/entree-icon.png" />
              </b-col>
            </div>
            <div class="row content-form switchBox">
              <b-col class="my-auto" style="padding:0;margin:0;">
                <b-row class="no-gutters">
                  <b-col cols="3">
                    <b-form-checkbox id="eca_switch" v-model="air" switch @input="changeAir"></b-form-checkbox>
                  </b-col>
                  <b-col cols="9" class="switch-label air active">
                    <label for="eca_switch">Entrée d'air acoustique</label>
                  </b-col>
                </b-row>
                <b-row class="no-gutters">
                  <b-col cols="3">
                    <b-form-checkbox id="rallonge_switch" v-model="rallonge" switch @input="changeRallonge"></b-form-checkbox>
                  </b-col>
                  <b-col cols="9" class="switch-label rallonge">
                    <label for="rallonge_switch">Entrée d'air acoustique avec rallonge</label>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Dimensions</span><br>du batiment</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/size-icon.png" />
              </b-col>
            </div>
            <div class="content-form inputBox">
              <b-row>
                <b-col>
                  <b-form-input v-model="form.longueur" placeholder="Longueur en mètres"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-input v-model="form.largeur" placeholder="Largeur en mètres"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8">
                <p><span>Architecture</span><br>du batiment</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/level-icon.png" />
              </b-col>
            </div>
            <div class="content-form spinner twolines">
              <b-row>
                <b-col cols="7">
                  <label for="sdb">Nombre de niveau(x)</label>
                </b-col>
                <b-col cols="3">
                  <b-form-spinbutton inline id="sdb" v-model="form.niveau" min="1" size="sm" @change="setNiveau"></b-form-spinbutton>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
              <label for="wc">Nombre de colonne(s)</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline id="wc" v-model="form.colonne" min="1" size="sm" @change="setNiveau"></b-form-spinbutton>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8" md="10">
                <p><span>Type</span><br> de Bâtiment</p>
              </b-col>
              <b-col cols="4" md="2">
                <b-img src="../assets/type-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton tripleline">
              <b-button v-for="(type_batiment,index) in batiment" :key="index" @click.prevent="setBatimentType(type_batiment)" :class="['batiment_type_' + type_batiment.id]" class="batiment_type">{{type_batiment.nom}}</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6" v-for="(type_locaux,index3) in selectedBatiment" :key="'A'+index3" class="type_locaux">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8" md="10">
                <p><span>{{first_word(type_locaux.name)}}</span> {{follow_word(type_locaux.name)}}</p>
              </b-col>
              <b-col cols="4" md="2">
              <b-img src="../assets/type-icon.png" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row v-for="(type_piece,index2) in type_locaux.piece" :key="'B'+index2">
                <b-col cols="8">
                  <label :for="type_piece.id">{{type_piece.name}}</label>
                </b-col>
                <b-col cols="3">
                  <b-form-spinbutton inline :id="type_piece.id" v-model="form.batiment[type_locaux.type][type_piece.id].number" min="0" size="sm" @change="setNumber(type_locaux.type,type_piece.id)"></b-form-spinbutton>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col  :class="[isRexel ? 'col-md-6 col-lg-6 col-12': 'col-md-6 col-lg-3 col-12', errorClass]" v-show="show[local.id]" v-for="(local,index4) in selectedLocal" :key="'C'+index4" class="type_piece">
          <div class="content-block shadow-box">
            <div class='header-form row align-items-center'>
              <b-col cols="8"><p>{{local.name}}</p></b-col>
              <b-col cols="4"><b-img src="../assets/type-icon.png" /></b-col>
            </div>
            <div class="content-form spinner">
              <b-row v-if="checkPersonne(local)">
                <b-col cols="8">
                  <label for="nb_pers">Nombre de Personne</label>
                </b-col>
                <b-col cols="4">
                  <b-form-input inline type="number" id="hall" v-model="form.batiment[local.type][local.id].personne" :disabled="form.batiment[local.type][local.id].surface!=0" :number="true" @change="setPersonne" min="0"></b-form-input>
                  </b-col>
              </b-row>
              <b-row v-if="checkPersonne(local) && checkSurface(local)">
                <b-col style="text-align:center">
                  <label>ou</label>
                </b-col>
              </b-row>
              <b-row v-if="checkSurface(local)">
                <b-col cols="8">
                  <label for="surface">Surface</label>
                </b-col>
                <b-col cols="4">
                  <b-form-input inline type="number" id="attente" v-model="form.batiment[local.type][local.id].surface" :disabled="form.batiment[local.type][local.id].personne!=0"  :number="true" @change="setPersonne" min="0"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="footer align-items-center no-gutters">
        <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
          <b-button @click="$router.go(-1)">
            <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
          </b-button>
        </b-col>
        <b-col md="8" cols="12" order="1" order-md="2"></b-col>
        <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
          <b-button type="submit" class="next" :disabled="!validate">
            <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-card class="mt-3" v-if="debugging" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </b-container>
</template>

<script>

export default {
  name: 'Tertiare',
  data() {
      return {
        isRexel:false,
        air: true,
        debugging:false,
        validate: false,
        reglementation: { existant:false, neuf:false},
        surface: [{ text: 'Surface', value: null },{ text: '100m²', value: '4' }, { text: '100 à 150m²', value: '6' }, { text: '150 à 200m²', value: '8' }],
        niveau: [{ text: 'Niveau Habitable', value: null },{ text: 'Rez de chaussée', value: '1' }, { text: '+1 Etage', value: '1.2' }, { text: '+2 Etage', value: '1.6' }],
        typologie: [{ text: 'Typologie', value: null },
        { text: 'T1', value: 1 },{ text: 'T2', value: 2 },{ text: 'T3', value: 3 },
        { text: 'T4', value: 4 },{ text: 'T5', value: 5 },{ text: 'T6', value: 6 },{ text: 'T7', value: 7 }],
        forme: [{ text: 'Forme', value: null },{ text: 'Carré', value: '1' },{ text: 'Rectangle', value: '1.2' },{ text: 'L', value: '1.4' }],
        emplacement: { centree:false, excentree:false},
        rallonge:false,
        changeECAR:false,
        show_DoubleFlux:false,
        show_SimpleFlux:true,
        hide_Alimentation:false,
        hide_aspi:false,
        hide_type_aspi_ligne:false,
        hide_entree_air:false,
        hide_exigeance:true,
        show: {},
        batiment:[],
        selectedBatiment: [],
        selectedLocal: [],
        items: [
          {
            text: 'Accueil',
            href: '/'
          },
          {
            text: 'Collectif',
            active: true
          }
        ],
      }
    },
    methods: {
      first_word(word){
        return word.split(" ")[0];
      },
      follow_word(word){
        var res = word.split(" ");
        res.shift();
        return res.join(" ");
      },
      onSubmit(evt) {
        evt.preventDefault();
        this.form.type = "tertiaire";
        if(this.form.version_type==undefined){
          this.form.version_type="S";
        }

        if(this.form.flux_type=="D"){
          this.form.aggrement = "N";
        }
        this.$store.dispatch('devis/setRequest',this.form);
        const baseURI = this.$api_url+'products'
        this.$http.post(baseURI,{"request":this.form})
        .then((result) => {
          this.$store.dispatch('products/setProducts',result.data);
          this.$router.push('list');
        })

      },
      checkPersonne(local){
        return Object.prototype.hasOwnProperty.call(local,'personne');
      },
      checkSurface(local){
        return Object.prototype.hasOwnProperty.call(local,'surface');
      },
      onReset(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      setReglementation(reglementation){
        let x = document.getElementsByClassName("reglementation");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(reglementation){
          case 'N':
            document.getElementsByClassName("reglementation_n")[0].classList.add('active');
          break;
          case 'E':
            document.getElementsByClassName("reglementation_e")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.reglementation = reglementation;
        this.form = res;
      },
      setFluxType(type){
        let res = this.form;
        let x = document.getElementsByClassName("flux_type");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(type){
          case 'S':
            document.getElementsByClassName("flux_type_S")[0].classList.add('active');
            this.show_DoubleFlux = false;
            this.show_SimpleFlux = true;
          break;
          case 'D':
            document.getElementsByClassName("flux_type_D")[0].classList.add('active');
            this.show_DoubleFlux = true;
            this.show_SimpleFlux = false;
            res.aggrement = "N";
          break;
        }
        if(this.form.aggrement == "N" && type=="S"){
          this.hide_Alimentation = this.hide_aspi = /*this.hide_exigeance =*/ true;
          res.reglementation = "E";
          res.aspiration = 'L';
          res.tension = 'M';
          this.hide_aspi = false;
          this.hide_entree_air = false;
          this.hide_type_aspi_ligne = false;
        }else if(this.form.aggrement == "400" && type=="S"){
          this.hide_Alimentation = /*this.hide_exigeance =*/ true;
          res.reglementation = "E";
          res.aspiration = null;
          res.tension = 'M';
          this.hide_aspi = false;
          this.hide_type_aspi_ligne = true;
          this.hide_entree_air = false;
        }else{
          this.hide_Alimentation = this.hide_aspi = /*this.hide_exigeance =*/ false;
          if(type=='D'){
            this.hide_aspi = true;
            this.hide_entree_air = true;
            this.hide_Alimentation = true;
            res.tension = 'M';
          }else{
            this.hide_entree_air = false;
            res.tension = null;
          }
          res.reglementation = null;
          res.aspiration = null;

        }
        res.flux_type = type;
        this.form = res;
      },
      setVersion(version){
        let x = document.getElementsByClassName("version_type");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(version){
          case 'S':
            document.getElementsByClassName("version_type_S")[0].classList.add('active');
          break;
          case 'Elec':
            document.getElementsByClassName("version_type_Elec")[0].classList.add('active');
          break;
          case 'Eau':
            document.getElementsByClassName("version_type_Eau")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.version_type = version;
        this.form = res;
      },
      setAggrement(aggrement){
        let res = this.form;
        let x = document.getElementsByClassName("aggrement_type");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(aggrement){
          case 'N':
            document.getElementsByClassName("aggrement_type_n")[0].classList.add('active');
          break;
          case '400':
            document.getElementsByClassName("aggrement_type_400")[0].classList.add('active');
          break;
        }
        if(this.form.flux_type == "S" && aggrement=="N"){
          this.hide_Alimentation = this.hide_aspi = /*this.hide_exigeance =*/ true;
          res.reglementation = "E";
          res.aspiration = 'L';
          res.tension = 'M';
          this.hide_aspi = false;
          this.hide_type_aspi_ligne = false;
          this.hide_entree_air = false;
        }else if(aggrement == "400" && this.form.flux_type=="S"){
          this.hide_Alimentation = /*this.hide_exigeance =*/ true;
          res.reglementation = "E";
          res.aspiration = null;
          res.tension = 'M';
          this.hide_aspi = false;
          this.hide_type_aspi_ligne = true;
          this.hide_entree_air = false;
        }else{
          this.hide_Alimentation = this.hide_aspi = /*this.hide_exigeance =*/ false;
          res.reglementation = null;
          res.aspiration = null;
          res.tension = null;
          if(this.form.flux_type=='D'){
            this.hide_aspi = true;
            this.hide_entree_air = true;
          }else{
            this.hide_entree_air = false;
          }
        }

        res.aggrement = aggrement;
        this.form = res;
      },
      setBatimentType(batiment){
        let x = document.getElementsByClassName("batiment_type");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        document.getElementsByClassName("batiment_type_"+batiment.id)[0].classList.add('active');
        let res = this.form;
        res.batiment = {};
        res.calcul = batiment;
        this.show = {};
        this.selectedLocal = [];
        for(var t = 0 ; t < batiment.params.items.length; t++){
          res.batiment[batiment.params.items[t].type] = {};
          console.log(res.batiment);
          for(var j = 0 ; j < batiment.params.items[t].piece.length; j++){
            this.selectedLocal.push(batiment.params.items[t].piece[j]);
            this.show[batiment.params.items[t].piece[j].id] = false;
            res.batiment[batiment.params.items[t].type][batiment.params.items[t].piece[j].id] = {
              number:0,
              personne:0,
              surface:0
            }
          }
        }
        this.form = res;
        this.selectedBatiment = batiment.params.items;
      },
      setTension(tension){
        let x = document.getElementsByClassName("tension");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(tension){
          case 'M':
            document.getElementsByClassName("tension_m")[0].classList.add('active');
          break;
          case 'T':
            document.getElementsByClassName("tension_t")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.tension = tension;
        this.form = res;
      },
      setAspiration(aspiration){
        let x = document.getElementsByClassName("aspiration");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(aspiration){
          case 'L':
            document.getElementsByClassName("aspiration_l")[0].classList.add('active');
          break;
          case '9':
            document.getElementsByClassName("aspiration_9")[0].classList.add('active');
          break;
          case 'D':
            document.getElementsByClassName("aspiration_d")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.aspiration = aspiration;
        this.form = res;
      },
      setNiveau(){
        console.log(this.form);
        let res = this.form;
        this.form = res;
      },
      setNumber(type,piece){
        let res = this.form;
        this.form = res;
        var local = this.selectedBatiment.find((item) => item.type == type);
        var pieceTMP = local.piece.find((item) => item.id == piece);
        this.show[piece] = (this.form.batiment[type][piece].number>0 && (Object.prototype.hasOwnProperty.call(pieceTMP,'personne') || Object.prototype.hasOwnProperty.call(pieceTMP,'surface')));
        this.$forceUpdate();
      },
      setPersonne(){
        let res = this.form;
        this.form = res;
        this.$forceUpdate();
      },
      changeRallonge(){
        if(!this.changeECAR){
          let res = this.form;
          this.changeECAR = true;
          this.air = !this.rallonge;
          res.rallonge = this.rallonge;
          if(this.rallonge){
            document.getElementsByClassName("rallonge")[0].classList.add('active');
            document.getElementsByClassName("air")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("rallonge")[0].classList.remove('active');
            document.getElementsByClassName("air")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeECAR = false;
        }
      },
      changeAir(){
        if(!this.changeECAR){
          let res = this.form;
          this.changeECAR = true;
          this.rallonge = !this.air;
          res.rallonge = this.rallonge;
          if(this.rallonge){
            document.getElementsByClassName("rallonge")[0].classList.add('active');
            document.getElementsByClassName("air")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("rallonge")[0].classList.remove('active');
            document.getElementsByClassName("air")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeECAR = false;
        }
      }
    },
    computed:{
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      toShow:function(){
        var vm = this;
        return function (id) {
            return vm.show[id];
        };
      },
      form: {
        get:function() {
          let state = {};
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
            console.log("Try retrieve old devis");
            state = this.$store.state.devis.devis['request'];
          }else{
            state['tension'] = state['aspiration'] = state['longueur'] = state['largeur'] = null;
            state['colonne'] = state['niveau'] = 1;
          }
          return state;
        },
        set:function(value){
          console.log(value);
          var batiment_validate = false;
          for(let bat in this.form.batiment) {
              for(let piece in this.form.batiment[bat]) {
                if(bat!="sortie"){
                  if(this.form.batiment[bat][piece].number > 0 && (this.form.batiment[bat][piece].personne>0 || this.form.batiment[bat][piece].surface>0)){
                    batiment_validate = true;
                    break;
                  }
                }else{
                  if(this.form.batiment[bat][piece].number > 0){
                    batiment_validate = true;
                    break;
                  }
                }
              }
          }
          console.log(batiment_validate);
          //simple flux / double flux
          let validate = false;
          if(this.form.flux_type=="S"){
            validate = this.form.flux_type!=null && this.form.aggrement!=null && this.form.tension!=null && this.form.aspiration!=null
              && this.form.longueur!=null && this.form.largeur!=null && batiment_validate;
          }else if(this.form.flux_type=="D"){
            validate = this.form.flux_type!=null && this.form.aggrement!=null && this.form.tension!=null
              && this.form.longueur!=null && this.form.largeur!=null && batiment_validate;
          }

          this.validate = validate;
        }
      },
    },mounted(){
      this.isRexel = this.$appName=="rexel";
      console.log("mouted");
      const baseURI = this.$api_url+'tertiaire';
      this.$http.get(baseURI)
      .then((result) => {
        console.log(result);
        this.batiment = result.data;
      });
      if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
        let state = this.$store.state.devis.devis['request'];
        this.rallonge=state['rallonge'];
        switch(state['flux_type']){
          case 'S':
            document.getElementsByClassName("flux_type_S")[0].classList.add('active');
            this.show_DoubleFlux = false;
            this.show_SimpleFlux = true;

            switch(state['aggrement']){
              case 'N':
                document.getElementsByClassName("aggrement_type_n")[0].classList.add('active');
              break;
              case '400':
                document.getElementsByClassName("aggrement_type_400")[0].classList.add('active');
              break;
            }
          break;
          case 'D':
            document.getElementsByClassName("flux_type_D")[0].classList.add('active');
            this.show_DoubleFlux = true;
            this.show_SimpleFlux = false;

            switch(state['version_type']){
              case 'S':
                document.getElementsByClassName("version_type_S")[0].classList.add('active');
              break;
              case 'Elec':
                document.getElementsByClassName("version_type_Elec")[0].classList.add('active');
              break;
              case 'Eau':
                document.getElementsByClassName("version_type_Eau")[0].classList.add('active');
              break;
            }
          break;
        }

        switch(state['aspiration']){
          case 'L':
            document.getElementsByClassName("aspiration_l")[0].classList.add('active');
          break;
          case '9':
            document.getElementsByClassName("aspiration_9")[0].classList.add('active');
          break;
          case 'D':
            document.getElementsByClassName("aspiration_d")[0].classList.add('active');
          break;
        }

        switch(state['tension']){
          case 'M':
            document.getElementsByClassName("tension_m")[0].classList.add('active');
          break;
          case 'T':
            document.getElementsByClassName("tension_t")[0].classList.add('active');
          break;
        }

        switch(state['reglementation']){
          case 'N':
            document.getElementsByClassName("reglementation_n")[0].classList.add('active');
          break;
          case 'E':
            document.getElementsByClassName("reglementation_e")[0].classList.add('active');
          break;
        }
        console.log(state);
        document.getElementsByClassName("batiment_type_"+state['calcul'].id)[0].classList.add('active');
      }
    }
}
</script>

<style lang="scss">
#tertiaire{
  //padding: 3em 3em 0;
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;

  h3{
    margin-bottom:2em;
  }
  @media (max-width: 576px) {
      padding:3em 0 0;
  }

  .main_content>.row>div { margin-bottom: 2em;
    .row { margin-bottom: 2em;}
    @media (max-width: 576px) {
        margin-bottom: 0;
    }
  }
  .type_locaux,.type_piece {
    margin-bottom:2em
  }
  .main_content {
    .col-12 div.content-block {
      .content-form{

        &.switchBox{
          padding-top: 1em;
          padding-bottom: 1.5em;
          .switch-label{
            font-size: 1.5em;
            text-align:left;

            &.active{
              color:var(--maincolor);
            }
          }
        }
        text-align: center;

        .custom-control-label {
          color: #666;
          text-align: left;
          font-size: 1.5em;
        }

        .custom-switch  {
          //padding-left: 3.25rem;
          .custom-control-label::before {
              //left: -2.25rem;
              //width: 1.75rem;
              pointer-events: all;
              border-radius: 1.5rem;
              padding: 0.9rem 1.75rem;
              background: transparent !important;
              box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
              border: 0px;
              top: 0.3rem
          }

          .custom-control-label::after {
              //top: calc(.25rem + 2px);
              //left: calc(-2.25rem + 2px);
              width: calc(1.9rem - 10px);
              height: calc(1.9rem - 10px);
              background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              border-radius: 2.5rem;
              top: 0.55rem;
              left:calc(-2.25rem + 5px);
              transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
            background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
            box-shadow: none;
          }

          .custom-control-input:checked~.custom-control-label::after {
              background: white;
              transform: translateX(1.6rem);
          }
        }

        &.checkBox{
          border-radius:1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          button{
            box-shadow: none;
            background: transparent !important;
            border:0px;
            color: #666;
            margin:1em 1em;
            padding:1em;
            text-transform: none;
            width: 81%;

            &.active{
              color: var(--maincolor);
              box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            }
          }
        }

        &.inputBox{
          input{
            background-color: transparent;
            border-radius: 2em;
            border:0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            color: #000;
            padding: 1.5em 1em;
            &::placeholder {
              color:#999;
            }
          }
        }
        &.spinner{
          text-align: left;
          &.twolines{
            padding-top: 2.2em;
          }
          .row { margin-bottom: 0em;}
          .b-form-spinbutton{
          margin-bottom:1em;
          border-radius:2em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          .btn.btn-sm {
            padding:0;
            color: #666;
          }
        }
        }

        &.roundButton {
          &.tripleline{
            button{
              width: 90px;
              height: 90px;
              font-size: 0.7em;
              margin: 11px 9px;
            }
          }
          &.labelline{
              padding-top: 1.5em;
              .carre.active,.rectangle.active,.forme_en_l.active { color: var(--maincolor); }
              .forme_c{
                &.active span{background: var(--maincolor);}
                span{
                  width:20px;
                  height:20px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_r {
                &.active span{background: var(--maincolor);}
                span{
                  width:15px;
                  height:28px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_l {
                &.active span{background: var(--maincolor);}
                span{
                  width:17px;
                  height:33px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }
          }
          &.oneline{
            button{
              font-size:0.8em;
              margin-left: 1.5em;
              margin-right: 1.5em;

              &.commande{
                margin-left: 0.5em;
                margin-right: 0.5em;
              }
            }
          }
          button{
            width: 60px;
            height: 60px;
            padding: 7px 6px;
            border-radius: 50%;
            font-size: 2em;
            text-align: center;
            color: #666666;
            background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
            border: 0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
            margin: 11px 10px;
            &.active{
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              color:var(--maincolor);
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
</style>
