import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import IndividuelAdmin from './components/IndividuelAdmin';
import CatalogueAdmin from './components/CatalogueAdmin';
import ImageAdmin from './components/ImageAdmin';
import CollectifAdmin from './components/CollectifAdmin';
import TertiaireAdmin from './components/TertiaireAdmin';
import DesenfumageAdmin from './components/DesenfumageAdmin';
import ScriptAdmin from './components/ScriptAdmin';

Vue.prototype.$http = axios;

//Leroy Merlin Theme
Vue.prototype.$appName = "leroy-merlin";
Vue.prototype.$path = '/leroy-merlin/';
Vue.prototype.$maincolor = '#78be1f';
Vue.prototype.$dev = true;
Vue.prototype.$api_url = 'https://estimair.intranet-solerpalau.fr:3001/leroy-merlin/';
Vue.prototype.$islm = true;
Vue.prototype.$isCatalogue = true;
Vue.prototype.$islm = false;
Vue.prototype.$isCatalogue = false;


//Demo Theme
Vue.prototype.$path = '/demo/';
Vue.prototype.$appName = "demo";
Vue.prototype.$dev = true;
Vue.prototype.$maincolor = '#3d7da5';
Vue.prototype.$api_url = "https://estimair.intranet-solerpalau.fr:3001/demo/";

//sonepar  cged Theme
Vue.prototype.$path = '/cged/';
Vue.prototype.$appName = "cged";
Vue.prototype.$maincolor = '#e30521';
Vue.prototype.$dev = false;
Vue.prototype.$api_url = 'https://estimair.intranet-solerpalau.fr:3000/cged/';
Vue.prototype.$url_api_client = 'https://cged.fr/';

Vue.config.productionTip = false;

//yes Theme
Vue.prototype.$path = '/yes/';
Vue.prototype.$appName = "yes";
Vue.prototype.$maincolor = '#0099db';
Vue.prototype.$dev = false;
Vue.prototype.$api_url = 'https://estimair.intranet-solerpalau.fr:3000/yes/';

//Rexel Theme
Vue.prototype.$path = '/rexel/';
Vue.prototype.$appName = "rexel";
Vue.prototype.$maincolor = '#00468c';
Vue.prototype.$dev = false;
Vue.prototype.$api_url = 'https://estimair.intranet-solerpalau.fr:3000/rexel/';

//DEV SP Theme
Vue.prototype.$path = '/base/';
Vue.prototype.$appName = "dev";
Vue.prototype.$dev = true;
Vue.prototype.$maincolor = '#ff0000';
Vue.prototype.$api_url = "https://estimair.intranet-solerpalau.fr:3001/dev/";


//SP Theme
Vue.prototype.$path = '/base/';
Vue.prototype.$appName = "prod";
Vue.prototype.$maincolor = '#ff0000';
Vue.prototype.$dev = false;
Vue.prototype.$api_url = 'https://estimair.intranet-solerpalau.fr:3000/base/';

//sonepar Theme
Vue.prototype.$path = '/sonepar/';
Vue.prototype.$appName = "sonepar";
Vue.prototype.$maincolor = '#183168';
Vue.prototype.$dev = false;
Vue.prototype.$api_url = 'https://estimair.intranet-solerpalau.fr:3000/sonepar/';
Vue.prototype.$url_api_client = 'https://sonepar.fr/';

Vue.component('IndividuelAdmin', IndividuelAdmin);
Vue.component('CatalogueAdmin', CatalogueAdmin);
Vue.component('CollectifAdmin', CollectifAdmin);
Vue.component('TertiaireAdmin', TertiaireAdmin);
Vue.component('DesenfumageAdmin', DesenfumageAdmin);
Vue.component('ImageAdmin', ImageAdmin);
Vue.component('ScriptAdmin', ScriptAdmin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
