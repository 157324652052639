<template>
  <b-container id="collectif" fluid="xl" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <span class='selected'>Config</span> <span class='decoration'>></span> Choix VMC <span class='decoration'>></span> Accessoires <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3>Sélectionnez la configuration de votre chantier</h3>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="main_content">
      <b-row>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Type</span><br> de VMC</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/categ-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setVMCType('A')" class="vmc_type vmc_type_A">Auto</b-button>
              <b-button @click.prevent="setVMCType('HA')" class="vmc_type vmc_type_HA">Hygro A</b-button>
              <b-button @click.prevent="setVMCType('HB')" class="vmc_type vmc_type_HB">Hygro B</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Type</span><br> de Commande <br> de Bouches</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/commande-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setCommande('M')" class="commande commande_M">Manuel</b-button>
              <b-button @click.prevent="setCommande('220V')" class="commande commande_220V">220 Volts</b-button>
              <b-button @click.prevent="setCommande('P')" class="commande commande_P">A pile</b-button>
              <b-button @click.prevent="setCommande('12V')" class="commande commande_12V" v-show="show['12V']">12 Volts</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Alimentation</span><br>Caisson</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/tension-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setTension('M')" class='tension tension_m'>Mono phase</b-button>
              <b-button @click.prevent="setTension('T')" class='tension tension_t'>Tri phase</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Configuration</span><br>Caisson</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/aspiration-icon.png" />
              </b-col>
            </div>
            <div class="content-form roundButton oneline">
              <b-button @click.prevent="setAspiration('L')" class='aspiration aspiration_l'>Ligne</b-button>
              <b-button @click.prevent="setAspiration('9')" class='aspiration aspiration_9'>90°</b-button>
              <b-button @click.prevent="setAspiration('D')" class='aspiration aspiration_d'>Double</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Dimension</span><br>du batiment</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/size-icon.png" />
              </b-col>
            </div>
            <div class="content-form inputBox">
              <b-row>
                <b-col>
                  <b-form-input v-model="form.longueur" placeholder="Longueur en mètres"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-input v-model="form.largeur" placeholder="Largeur en mètres"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Emplacement</span><br>réseau horizontal</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/emplacement-reseau.png" />
              </b-col>
            </div>
            <div class="row content-form switchBox">
              <b-col class="my-auto" style="padding:0;margin:0;">
                <b-row class="no-gutters">
                  <b-col cols="3">
                    <b-form-checkbox id="comble_switch" v-model="comble" switch @input="changeComble"></b-form-checkbox>
                  </b-col>
                  <b-col cols="9" class="switch-label comble active">
                    <label for="comble_switch">En Comble</label>
                  </b-col>
                </b-row>
                <b-row class="no-gutters">
                  <b-col cols="3">
                    <b-form-checkbox id="terrasse_switch" v-model="terrasse" switch @input="changeTerrasse"></b-form-checkbox>
                  </b-col>
                  <b-col cols="9" class="switch-label terrasse">
                    <label for="terrasse_switch">En Terrasse</label>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Architecture</span><br>du batiment</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/level-icon.png" />
              </b-col>
            </div>
            <div class="content-form spinner twolines">
              <b-col class="my-auto" style="padding:0;margin:0;">
                <b-row class="no-gutters">
                  <b-col cols="6" style="padding-right:0;padding-left:0">
                    <label for="sdb">Nombre de niveau(x)</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-spinbutton inline id="sdb" v-model="form.niveau" min="1" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
                  </b-col>
                </b-row>
                <b-row  class="no-gutters">
                  <b-col cols="6">
                    <label for="wc">Nombre de colonne(s)</label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-spinbutton inline id="wc" v-model="form.colonne" min="1" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Type</span><br>d'entrée d'air</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/entree-icon.png" />
              </b-col>
            </div>
            <div class="row content-form switchBox">
              <b-col class="my-auto" style="padding:0;margin:0;">
                <b-row class="no-gutters">
                  <b-col cols="3">
                    <b-form-checkbox id="eca_switch" v-model="air" switch @input="changeAir"></b-form-checkbox>
                  </b-col>
                  <b-col cols="9" class="switch-label air active">
                    <label for="eca_switch">Entrée d'air acoustique</label>
                  </b-col>
                </b-row>
                <b-row class="no-gutters">
                  <b-col cols="3">
                    <b-form-checkbox id="rallonge_switch" v-model="rallonge" switch @input="changeRallonge"></b-form-checkbox>
                  </b-col>
                  <b-col cols="9" class="switch-label rallonge">
                    <label for="rallonge_switch">Entrée d'air acoustique avec rallonge</label>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Configuration</span><br>Appartements</p>
              </b-col>
              <b-col cols="4">
                <b-img style="width:20%" src="../assets/form-icon.png" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-col class="my-auto" style="padding:0;margin:0;">
                <b-row class="no-gutters">
                  <b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T1" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T1</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T1" v-model="configAppartement[1]" switch @input="updateConfig(1)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col><b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T2" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T2</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T2" v-model="configAppartement[2]" switch @input="updateConfig(2)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row>
                    </b-col><b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T3" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T3</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T3" v-model="configAppartement[3]" switch @input="updateConfig(3)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row>
                    </b-col><b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T4" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T4</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T4" v-model="configAppartement[4]" switch @input="updateConfig(4)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col><b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T5" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T5</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T5" v-model="configAppartement[5]" switch @input="updateConfig(5)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row></b-col><b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T6" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T6</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T6" v-model="configAppartement[6]" switch @input="updateConfig(6)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row></b-col><b-col>
                    <b-row class="no-gutters" style="margin-bottom:1em;">
                      <b-col cols="1" style="margin-right: 1em;">
                        <label for="T7" style="vertical-align: -webkit-baseline-middle;margin-bottom:0">T7</label>
                      </b-col>
                      <b-col cols="6" lg="6" md="8" sm="6">
                        <b-form-checkbox inline id="T7" v-model="configAppartement[7]" switch @input="updateConfig(7)" class="small-version"></b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="3" v-for="(config, index) in tempT" :key="index" style="margin-bottom: 2em;">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Pièces</span><br>techniques T{{config.typo}}</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'nb_T'+(index)">Nombre d'appartement</label>
                </b-col>
                <b-col cols="3">
                  <b-form-spinbutton inline :id="'nb_T'+(index)" v-model="config.number" min="1" size="sm" @change="setConfig" class="small-version"></b-form-spinbutton>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'sdb_T'+(index)">Salle de bain</label>
                </b-col>
                <b-col cols="3">
                  <b-form-spinbutton inline :id="'sdb_T'+(index)" v-model="config.sdb" min="0" max="3" size="sm" @change="setConfig" class="small-version"></b-form-spinbutton>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
              <label :for="'wc'+(index)">WC</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline :id="'wc'+(index)" v-model="config.wc" min="0" max="3" size="sm" @change="setConfig" class="small-version"></b-form-spinbutton>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <label :for="'sdb_wc_T'+(index)">Salle de bain + WC</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline :id="'sdb_wc_T'+(index)" v-model="config.sdb_wc" min="0" max="3" size="sm" @change="setConfig" class="small-version"></b-form-spinbutton>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <label :for="'eau_T'+(index)">Salle d'eau</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline :id="'eau_T'+(index)" v-model="config.eau" min="0" max="3" size="sm" @change="setConfig" class="small-version"></b-form-spinbutton>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button block variant="light" class="btn" @click.prevent="addRemoveConfig(config.typo,index,true)">Autres Configuration <b-icon icon="plus"></b-icon></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" style="text-align:left;">
              <b-button variant="danger" class="btn" @click.prevent="addRemoveConfig(config.typo,index,false)"><b-icon icon="trash-fill"></b-icon></b-button>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <!--<b-row>
        <b-col cols="12" md="6" lg="3" v-show="show['T'+(index)]" v-for="index in 7" :key="index">
          <div class="content-block shadow-box">
            <div class='row header-form align-items-center' style="margin-bottom:0em;">
              <b-col cols="8">
                <p><span>Pièces</span><br>techniques T{{(index)}}</p>
              </b-col>
              <b-col cols="4">
                <b-img src="../assets/piece-icon.png" />
              </b-col>
            </div>
            <div class="content-form spinner">
              <b-row>
                <b-col cols="7">
                  <label :for="'nb_T'+(index)">Nombre d'appartement</label>
                </b-col>
                <b-col cols="3">
                  <b-form-spinbutton inline :id="'nb_T'+(index)" v-model="form.T[index-1].sdb" min="0" max="3" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <label :for="'sdb_T'+(index)">Salle de bain</label>
                </b-col>
                <b-col cols="3">
                  <b-form-spinbutton inline :id="'sdb_T'+(index)" v-model="form.T[index-1].sdb" min="0" max="3" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
              <label :for="'wc'+(index)">WC</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline :id="'wc'+(index)" v-model="form.T[index-1].wc" min="0" max="3" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <label :for="'sdb_wc_T'+(index)">Salle de bain + WC</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline :id="'sdb_wc_T'+(index)" v-model="form.T[index-1].sdb_wc" min="0" max="3" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <label :for="'eau_T'+(index)">Salle d'eau'</label>
            </b-col>
            <b-col cols="3">
              <b-form-spinbutton inline :id="'eau_T'+(index)" v-model="form.T[index-1].eau" min="0" max="3" size="sm" @change="setNiveauCollectif" class="small-version"></b-form-spinbutton>
            </b-col>
          </b-row>
            </div>
          </div>
        </b-col>
      </b-row>-->
    <b-row class="footer align-items-center no-gutters">
      <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
        <b-button @click="$router.go(-1)">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2"></b-col>
      <b-col md="2" cols="6" order="3" order-md="3" style="text-align:right;">
        <b-button type="submit" class="next" :disabled="!validate">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
    </b-row>
    </b-form>
    <b-card class="mt-3" v-if="debugging" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: 'Collectif',
  data() {
      return {
        air: true,
        comble: true,
        terrasse:false,
        changeEmplReseau: false,
        debugging:false,
        validate: false,
        reglementation: { existant:false, neuf:false},
        surface: [{ text: 'Surface', value: null },{ text: '100m²', value: '4' }, { text: '100 à 150m²', value: '6' }, { text: '150 à 200m²', value: '8' }],
        niveau: [{ text: 'Niveau Habitable', value: null },{ text: 'Rez de chaussée', value: '1' }, { text: '+1 Etage', value: '1.2' }, { text: '+2 Etage', value: '1.6' }],
        typologie: [{ text: 'Typologie', value: null },
        { text: 'T1', value: 1 },{ text: 'T2', value: 2 },{ text: 'T3', value: 3 },
        { text: 'T4', value: 4 },{ text: 'T5', value: 5 },{ text: 'T6', value: 6 },{ text: 'T7', value: 7 }],
        forme: [{ text: 'Forme', value: null },{ text: 'Carré', value: '1' },{ text: 'Rectangle', value: '1.2' },{ text: 'L', value: '1.4' }],
        emplacement: { centree:false, excentree:false},
        rallonge:false,
        changeECAR:false,
        show: {T1:false,T2:false,T3:false,T4:false,T5:false,T6:false,T7:false,'12V':false},
        tempT: [],
        configAppartement:{1:0,2:0,3:0,4:0,5:0,6:0,7:0},
        finalConfigAppartement:{1:0,2:0,3:0,4:0,5:0,6:0,7:0},
        items: [
          {
            text: 'Accueil',
            href: '/'
          },
          {
            text: 'Collectif',
            active: true
          }
        ],
      }
    },
    methods: {
      addRemoveConfig(typo,index,toAdd){
        console.log(typo);console.log(index);console.log(toAdd);
        if(toAdd){
          this.tempT.splice((index+1), 0, {typo:typo,number:1,sdb:0,sdb_wc:0,eau:0,wc:0});
        }else{
          this.tempT.splice(index, 1);
          var found = this.tempT.find(element => element.typo==typo);
          if(found==undefined){
            this.configAppartement[typo] = false;
          }
        }
      },
      updateConfig(typo){
        console.log(this.configAppartement[typo]);
        if(this.configAppartement[typo]){
          let toAdd = 0;
          let found = false;
          if(this.tempT.length>0){
            for (var j= 0 ; j < this.tempT.length; j++) {
              toAdd = j;
              if(this.tempT[j].typo>typo){
                found = true;
                break;
              }
            }
          }
          if(!found){
            toAdd++;
          }
          this.tempT.splice(toAdd, 0, {typo:typo,number:1,sdb:0,sdb_wc:0,eau:0,wc:0});
        }else{
          var toDel = new Array();
          for (var i = 0 ; i < this.tempT.length; i++) {
            if(this.tempT[i].typo==typo){
              var tempType = this.tempT[i].typo - 1;
              toDel.push(i);
              this.form.T[tempType].number -= this.tempT[i].number;
              this.form.T[tempType].sdb -= this.tempT[i].sdb;
              this.form.T[tempType].sdb_wc -= this.tempT[i].sdb_wc;
              this.form.T[tempType].eau -= this.tempT[i].eau;
              this.form.T[tempType].wc -= this.tempT[i].wc;
            }
          }

          toDel = toDel.sort(function(a, b){
            return b - a;
          });
          console.log(toDel);
          for(var t = 0 ; t < toDel.length; t++){
            this.tempT.splice(toDel[t], 1);
          }
        }

        this.form.ConfigTypo = this.tempT;
        let validate = ((this.form.T[0].number!=0 && (this.form.T[0].sdb!=0 || this.form.T[0].sdb_wc!=0 || this.form.T[0].wc!=0 || this.form.T[0].eau!=0))
          || (this.form.T[1].number!=0 && (this.form.T[1].sdb!=0 || this.form.T[1].sdb_wc!=0 || this.form.T[1].wc!=0 || this.form.T[1].eau!=0))
          || (this.form.T[2].number!=0 && (this.form.T[2].sdb!=0 || this.form.T[2].sdb_wc!=0 || this.form.T[2].wc!=0 || this.form.T[2].eau!=0))
          || (this.form.T[3].number!=0 && (this.form.T[3].sdb!=0 || this.form.T[3].sdb_wc!=0 || this.form.T[3].wc!=0 || this.form.T[3].eau!=0))
          || (this.form.T[4].number!=0 && (this.form.T[4].sdb!=0 || this.form.T[4].sdb_wc!=0 || this.form.T[4].wc!=0 || this.form.T[4].eau!=0))
          || (this.form.T[5].number!=0 && (this.form.T[5].sdb!=0 || this.form.T[5].sdb_wc!=0 || this.form.T[5].wc!=0 || this.form.T[5].eau!=0))
          || (this.form.T[6].number!=0 && (this.form.T[6].sdb!=0 || this.form.T[6].sdb_wc!=0 || this.form.T[6].wc!=0 || this.form.T[6].eau!=0)))
          && this.form.vmc_type!=null && this.form.tension!=null && this.form.aspiration!=null
          && this.form.commande!=null && this.form.longueur!=null && this.form.largeur!=null;
        console.log(validate);
        this.validate = validate;
      },
      onSubmit(evt) {
        evt.preventDefault();
        this.form.type = "collectif";
        this.$store.dispatch('devis/setRequest',this.form);
        const baseURI = this.$api_url+'products'
        this.$http.post(baseURI,{"request":this.form})
        .then((result) => {
          this.$store.dispatch('products/setProducts',result.data);
          this.$router.push('list');
        })

      },
      onReset(evt) {
        evt.preventDefault();
        this.$router.go(-1);
      },
      setVMCType(type){
        let x = document.getElementsByClassName("vmc_type");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(type){
          case 'A':
            this.show['12V'] = false;
            document.getElementsByClassName("vmc_type_A")[0].classList.add('active');
          break;
          case 'HA':
            this.show['12V'] = true;
            document.getElementsByClassName("vmc_type_HA")[0].classList.add('active');
          break;
          case 'HB':
            this.show['12V'] = true;
            document.getElementsByClassName("vmc_type_HB")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.vmc_type = type;
        this.form = res;
      },
      setCommande(commande){
        let x = document.getElementsByClassName("commande");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(commande){
          case 'M':
            document.getElementsByClassName("commande_M")[0].classList.add('active');
          break;
          case '220V':
            document.getElementsByClassName("commande_220V")[0].classList.add('active');
          break;
          case 'P':
            document.getElementsByClassName("commande_P")[0].classList.add('active');
          break;
          case '12V':
            document.getElementsByClassName("commande_12V")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.commande = commande;
        this.form = res;
      },
      setTension(tension){
        let x = document.getElementsByClassName("tension");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(tension){
          case 'M':
            document.getElementsByClassName("tension_m")[0].classList.add('active');
          break;
          case 'T':
            document.getElementsByClassName("tension_t")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.tension = tension;
        this.form = res;
      },
      setAspiration(aspiration){
        let x = document.getElementsByClassName("aspiration");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        switch(aspiration){
          case 'L':
            document.getElementsByClassName("aspiration_l")[0].classList.add('active');
          break;
          case '9':
            document.getElementsByClassName("aspiration_9")[0].classList.add('active');
          break;
          case 'D':
            document.getElementsByClassName("aspiration_d")[0].classList.add('active');
          break;
        }
        let res = this.form;
        res.aspiration = aspiration;
        this.form = res;
      },
      setNiveauCollectif(){
        console.log(this.form);
        let res = this.form;
        this.form = res;
      },
      setConfig(){
        this.form['T'] = [
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
          {number:0,sdb:0,sdb_wc:0,eau:0,wc:0}
        ]
        var self = this;
        this.tempT.forEach(function(config){
          var tempType = config.typo - 1;
          self.form.T[tempType].number += config.number;
          self.form.T[tempType].sdb += config.sdb;
          self.form.T[tempType].sdb_wc += config.sdb_wc;
          self.form.T[tempType].eau += config.eau;
          self.form.T[tempType].wc += config.wc;
        });
        this.form.ConfigTypo = this.tempT;
        let validate = ((this.form.T[0].number!=0 && (this.form.T[0].sdb!=0 || this.form.T[0].sdb_wc!=0 || this.form.T[0].wc!=0 || this.form.T[0].eau!=0))
          || (this.form.T[1].number!=0 && (this.form.T[1].sdb!=0 || this.form.T[1].sdb_wc!=0 || this.form.T[1].wc!=0 || this.form.T[1].eau!=0))
          || (this.form.T[2].number!=0 && (this.form.T[2].sdb!=0 || this.form.T[2].sdb_wc!=0 || this.form.T[2].wc!=0 || this.form.T[2].eau!=0))
          || (this.form.T[3].number!=0 && (this.form.T[3].sdb!=0 || this.form.T[3].sdb_wc!=0 || this.form.T[3].wc!=0 || this.form.T[3].eau!=0))
          || (this.form.T[4].number!=0 && (this.form.T[4].sdb!=0 || this.form.T[4].sdb_wc!=0 || this.form.T[4].wc!=0 || this.form.T[4].eau!=0))
          || (this.form.T[5].number!=0 && (this.form.T[5].sdb!=0 || this.form.T[5].sdb_wc!=0 || this.form.T[5].wc!=0 || this.form.T[5].eau!=0))
          || (this.form.T[6].number!=0 && (this.form.T[6].sdb!=0 || this.form.T[6].sdb_wc!=0 || this.form.T[6].wc!=0 || this.form.T[6].eau!=0)))
          && this.form.vmc_type!=null && this.form.tension!=null && this.form.aspiration!=null
          && this.form.commande!=null && this.form.longueur!=null && this.form.largeur!=null;
        console.log(validate);
        this.validate = validate;
      },
      changeRallonge(){
        if(!this.changeECAR){
          let res = this.form;
          this.changeECAR = true;
          this.air = !this.rallonge;
          res.rallonge = this.rallonge;
          if(this.rallonge){
            document.getElementsByClassName("rallonge")[0].classList.add('active');
            document.getElementsByClassName("air")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("rallonge")[0].classList.remove('active');
            document.getElementsByClassName("air")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeECAR = false;
        }
      },
      changeAir(){
        if(!this.changeECAR){
          let res = this.form;
          this.changeECAR = true;
          this.rallonge = !this.air;
          res.rallonge = this.rallonge;
          if(this.rallonge){
            document.getElementsByClassName("rallonge")[0].classList.add('active');
            document.getElementsByClassName("air")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("rallonge")[0].classList.remove('active');
            document.getElementsByClassName("air")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeECAR = false;
        }
      },
      changeTerrasse(){
        console.log(this.changeEmplReseau);
        if(!this.changeEmplReseau){
          let res = this.form;
          this.changeEmplReseau = true;
          this.comble = !this.terrasse;
          res.terrasse = this.terrasse;
          if(this.terrasse){
            document.getElementsByClassName("terrasse")[0].classList.add('active');
            document.getElementsByClassName("comble")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("terrasse")[0].classList.remove('active');
            document.getElementsByClassName("comble")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeEmplReseau = false;
        }
      },
      changeComble(){
        if(!this.changeEmplReseau){
          let res = this.form;
          this.changeEmplReseau = true;
          this.terrasse = !this.comble;
          res.terrasse = this.terrasse;
          if(this.terrasse){
            document.getElementsByClassName("terrasse")[0].classList.add('active');
            document.getElementsByClassName("comble")[0].classList.remove('active');
          }else{
            document.getElementsByClassName("terrasse")[0].classList.remove('active');
            document.getElementsByClassName("comble")[0].classList.add('active');
          }
          this.form = res;
        }else{
          this.changeEmplReseau = false;
        }
      }
    },
    computed:{
      cssProps() {
        return {
          '--maincolor': this.$maincolor
        }
      },
      form: {
        get:function() {
          let state = {};
          if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
            console.log("Try retrieve old devis");
            state = this.$store.state.devis.devis['request'];
          }else{
            state['vmc_type'] = state['commande'] = state['tension'] = state['aspiration'] = state['longueur'] = state['largeur'] = null;
            state['colonne'] = state['niveau'] = 1;
            state['T'] = [
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0},
              {number:0,sdb:0,sdb_wc:0,eau:0,wc:0}
            ]
          }
          return state;
        },
        set:function(value){
          console.log('set form');
          console.log(value);
          console.log(this.form);
          if(this.form.T[0].number>0){
            this.show['T1'] = true;
          }else{
            this.show['T1'] = false;
          }
          if(this.form.T[1].number>0){
            this.show['T2'] = true;
          }else{
            this.show['T2'] = false;
          }
          if(this.form.T[2].number>0){
            this.show['T3'] = true;
          }else{
            this.show['T3'] = false;
          }
          if(this.form.T[3].number>0){
            this.show['T4'] = true;
          }else{
            this.show['T4'] = false;
          }
          if(this.form.T[4].number>0){
            this.show['T5'] = true;
          }else{
            this.show['T5'] = false;
          }
          if(this.form.T[5].number>0){
            this.show['T6'] = true;
          }else{
            this.show['T6'] = false;
          }
          if(this.form.T[6].number>0){
            this.show['T7'] = true;
          }else{
            this.show['T7'] = false;
          }

          let validate = ((this.form.T[0].number!=0 && (this.form.T[0].sdb!=0 || this.form.T[0].sdb_wc!=0 || this.form.T[0].wc!=0 || this.form.T[0].eau!=0))
            || (this.form.T[1].number!=0 && (this.form.T[1].sdb!=0 || this.form.T[1].sdb_wc!=0 || this.form.T[1].wc!=0 || this.form.T[1].eau!=0))
            || (this.form.T[2].number!=0 && (this.form.T[2].sdb!=0 || this.form.T[2].sdb_wc!=0 || this.form.T[2].wc!=0 || this.form.T[2].eau!=0))
            || (this.form.T[3].number!=0 && (this.form.T[3].sdb!=0 || this.form.T[3].sdb_wc!=0 || this.form.T[3].wc!=0 || this.form.T[3].eau!=0))
            || (this.form.T[4].number!=0 && (this.form.T[4].sdb!=0 || this.form.T[4].sdb_wc!=0 || this.form.T[4].wc!=0 || this.form.T[4].eau!=0))
            || (this.form.T[5].number!=0 && (this.form.T[5].sdb!=0 || this.form.T[5].sdb_wc!=0 || this.form.T[5].wc!=0 || this.form.T[5].eau!=0))
            || (this.form.T[6].number!=0 && (this.form.T[6].sdb!=0 || this.form.T[6].sdb_wc!=0 || this.form.T[6].wc!=0 || this.form.T[6].eau!=0)))
            && this.form.vmc_type!=null && this.form.tension!=null && this.form.aspiration!=null
            && this.form.commande!=null && this.form.longueur!=null && this.form.largeur!=null;
          console.log(validate);
          this.validate = validate;
        }
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log("mouted");
      if(this.$store.state.devis && this.$store.state.devis.devis && this.$store.state.devis.devis['request']!=undefined){
        let state = this.$store.state.devis.devis['request'];
        this.rallonge=state['rallonge'];
        this.terrasse = state['terrasse'];
        switch(state['vmc_type']){
          case 'A':
            document.getElementsByClassName("vmc_type_A")[0].classList.add('active');
          break;
          case 'HA':
            document.getElementsByClassName("vmc_type_HA")[0].classList.add('active');
          break;
          case 'HB':
            document.getElementsByClassName("vmc_type_HB")[0].classList.add('active');
          break;
        }

        switch(state['commande']){
          case 'M':
            document.getElementsByClassName("commande_M")[0].classList.add('active');
          break;
          case 'E':
            document.getElementsByClassName("commande_E")[0].classList.add('active');
          break;
          case 'P':
            document.getElementsByClassName("commande_P")[0].classList.add('active');
          break;
          case '12V':
            document.getElementsByClassName("commande_12V")[0].classList.add('active');
          break;
        }

        switch(state['aspiration']){
          case 'L':
            document.getElementsByClassName("aspiration_l")[0].classList.add('active');
          break;
          case '9':
            document.getElementsByClassName("aspiration_9")[0].classList.add('active');
          break;
          case 'D':
            document.getElementsByClassName("aspiration_d")[0].classList.add('active');
          break;
        }

        switch(state['tension']){
          case 'M':
            document.getElementsByClassName("tension_m")[0].classList.add('active');
          break;
          case 'T':
            document.getElementsByClassName("tension_t")[0].classList.add('active');
          break;
        }
        if(state.ConfigTypo){
          var configTypoTmp = state.ConfigTypo;
          var self = this;
          configTypoTmp.forEach(function(config){
            self.configAppartement[config.typo]=true;
          });
          setTimeout(function(){
            self.tempT = configTypoTmp;
            self.form.ConfigTypo = self.tempT;
          },50);

        }

        let validate = ((this.form.T[0].number!=0 && (this.form.T[0].sdb!=0 || this.form.T[0].sdb_wc!=0 || this.form.T[0].wc!=0 || this.form.T[0].eau!=0))
          || (this.form.T[1].number!=0 && (this.form.T[1].sdb!=0 || this.form.T[1].sdb_wc!=0 || this.form.T[1].wc!=0 || this.form.T[1].eau!=0))
          || (this.form.T[2].number!=0 && (this.form.T[2].sdb!=0 || this.form.T[2].sdb_wc!=0 || this.form.T[2].wc!=0 || this.form.T[2].eau!=0))
          || (this.form.T[3].number!=0 && (this.form.T[3].sdb!=0 || this.form.T[3].sdb_wc!=0 || this.form.T[3].wc!=0 || this.form.T[3].eau!=0))
          || (this.form.T[4].number!=0 && (this.form.T[4].sdb!=0 || this.form.T[4].sdb_wc!=0 || this.form.T[4].wc!=0 || this.form.T[4].eau!=0))
          || (this.form.T[5].number!=0 && (this.form.T[5].sdb!=0 || this.form.T[5].sdb_wc!=0 || this.form.T[5].wc!=0 || this.form.T[5].eau!=0))
          || (this.form.T[6].number!=0 && (this.form.T[6].sdb!=0 || this.form.T[6].sdb_wc!=0 || this.form.T[6].wc!=0 || this.form.T[6].eau!=0)))
          && this.form.vmc_type!=null && this.form.tension!=null && this.form.aspiration!=null
          && this.form.commande!=null && this.form.longueur!=null && this.form.largeur!=null;
        console.log(validate);
        this.validate = validate;
      }
    }
}
</script>

<style lang="scss">
#collectif{
  //padding: 3em 3em 0;
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;

  h3{
    margin-bottom:2em;
  }
  @media (max-width: 576px) {
      padding:3em 0 0;
  }
  .row { margin-bottom: 2em;
    @media (max-width: 576px) {
        margin-bottom: 0;
    }
  }

  .main_content {
    .col-12 div.content-block {
      &.selected_block{
        border: 2px solid var(--maincolor);
      }
      padding: 1em 1em 1.5em 1em;
      .content-form{

        &.switchBox{
          padding-top: 1em;
          padding-bottom: 1.5em;
          .switch-label{
            font-size: 1.5em;
            text-align:left;
            padding-left:15px;
            &.active{
              color:var(--maincolor);
            }
          }
        }
        text-align: center;

        .custom-control-label {
          color: #666;
          text-align: left;
          font-size: 1.5em;
        }

        .custom-switch  {
          //padding-left: 3.25rem;
          .custom-control-label::before {
              //left: -2.25rem;
              //width: 1.75rem;
              pointer-events: all;
              border-radius: 1.5rem;
              padding: 0.9rem 1.75rem;
              background: transparent !important;
              box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
              border: 0px;
              top: 0.3rem
          }

          .custom-control-label::after {
              //top: calc(.25rem + 2px);
              //left: calc(-2.25rem + 2px);
              width: calc(1.9rem - 10px);
              height: calc(1.9rem - 10px);
              background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              border-radius: 2.5rem;
              top: 0.55rem;
              left:calc(-2.25rem + 5px);
              transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .custom-control-input:checked~.custom-control-label::before, .custom-control-input:disabled:checked~.custom-control-label::before{
            background: linear-gradient(-122deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%) !important;
            box-shadow: none;
          }

          .custom-control-input:checked~.custom-control-label::after {
              background: white;
              transform: translateX(1.6rem);
          }
        }

        &.checkBox{
          margin-bottom:4em;
          margin-top: 5em;
          border-radius:1em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          button{
            box-shadow: none;
            background: transparent !important;
            border:0px;
            color: #666;
            margin:1em 1em;
            padding:1em;
            text-transform: none;
            width: 81%;

            &.active{
              color: var(--maincolor);
              box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            }
          }
        }

        &.inputBox{
          padding-top: 1.5em;
          input{
            background-color: transparent;
            border-radius: 2em;
            border:0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
            color: #000;
            padding: 1.5em 1em;
            &::placeholder {
              color:#999;
            }
          }
        }
        &.spinner{
          text-align: left;
          &.twolines{
            padding-top: 3.2em;
            padding-bottom: 2.5em;
          }
          .row { margin-bottom: 0em;}
          .b-form-spinbutton{
          &.small-version{
            .btn.btn-sm {
              &:first-of-type{
                margin-right:0;
              }
              &:last-of-type{
                margin-left:0;
              }
            }
            output{
              padding:0;
            }
          }
          margin-bottom:1em;
          border-radius:2em;
          background: linear-gradient(-122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
          color: #666;
          box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
          border:0;

          .btn.btn-sm {
            padding:0;
            color: #666;
          }
        }
        }
        &.roundButton {
          &.labelline{
              .carre.active,.rectangle.active,.forme_en_l.active { color: var(--maincolor); }
              .forme_c{
                &.active span{background: var(--maincolor);}
                span{
                  width:20px;
                  height:20px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_r {
                &.active span{background: var(--maincolor);}
                span{
                  width:15px;
                  height:28px;
                  background: #666;
                  display:block;
                  margin: 0 auto;
                }
              }

              .forme_l {
                &.active span{
                  background: var(--maincolor);
                  span.head_button {
                    background: var(--maincolor);
                  }
                }
                span{
                  width:11px;
                  height:33px;
                  background: #666;
                  display:block;
                  position: relative;
                  margin: 0 25%;
                    span.head_button{
                    width:20px;
                    height:11px;
                    background: #666;
                    display:block;
                    position: absolute;
                    bottom:0;
                    left:0;
                  }
                }
              }
          }
          &.oneline{
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top:0.5em;
            button{
              font-size:0.8em;
            }
          }
          button{
            width: 60px;
            height: 60px;
            padding: 7px 6px;
            border-radius: 50%;
            font-size: 1em;
            text-align: center;
            color: #666666;
            background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
            border: 0px;
            box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
            &.active{
              box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
              color: var(--maincolor);
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
</style>
