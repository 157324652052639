<template>
  <b-container id="list" :style="cssProps">
    <div class="header inner-shadow-box">
      <router-link to="/"><b-img :src='"/img/"+$path+"logo_minus_text.png"' fluid alt="Logo Estimair" id="estimair-logo"></b-img><b-img :src='"/img/"+$path+"logo-business.png"' fluid alt="Logo" id="logo-business"></b-img></router-link>
    </div>
    <div class="arianne">
      <p><router-link to="/">Accueil</router-link> <span class='decoration'>></span> <router-link :to="prev">Config</router-link> <span class='decoration'>></span> <span class='selected'>{{list_label}}</span> <span class='decoration'>></span> Accessoires <span class='decoration'>></span> Données <span class='decoration'>></span> PDF</p>
    </div>
    <h3 style="display:inline-block;">Choisissez un produit ci-dessous </h3>
    <b-button-group class="flex-wrap filter">
      <b-button pill v-for="(categorie,index) in categories" :key=categorie @click="selectCategorie(categorie,$event)" :class="{ 'active': index === 0 }">{{categorie}}</b-button>
    </b-button-group>
    <b-row class="no-gutter">
      <b-col cols="12" md="6" lg="3" v-for="product in products" :disabled="checkPieceTechnique(product)" :key="product.lidic" href="#" @click="selectVMC(product,$event)" class="product-item" :class="[{ disabled: checkPieceTechnique(product) }, product.featured==1?'featured':'']">
        <h5 v-html="product.nom"></h5>
        <b-img :src="`/img/${product.lidic}.jpg`" fluid class='vignette'/>
        <p v-html="product.designation"></p>
        <h6 v-if="classic && product.categorie!='Double Flux' && !product.featured"><b-img src="../assets/piece-icon.png" /> <span>{{product.piece_technique}} pièces techniques maximum</span></h6>
        <h6 v-if="classic && product.categorie=='Double Flux' && !product.featured"><b-img src="../assets/piece-icon.png" /> <span>{{product.debit_max}} m3/h débit maximum</span></h6>
        <h6 v-if="classic && product.featured"><b-img src="../assets/featured.png" /> <span>Sélection S&amp;P</span><span v-if="product.feature_slug!=''" style="text-transform: none;display: block;text-align:center;margin-top: 0.3em;">({{product.feature_slug}})</span></h6>
        <div class='button-next'>
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </div>
      </b-col>
    </b-row>
    <!--<div class="card mt-3">
      <div class="card-body" style="padding: 3.25rem 0 0;">
        <b-list-group v-if="products">
          <b-list-group-item >
            <b-row>
              <b-col cols="12" md="2">
                <b-img :src="`/img/${product.lidic}.jpg`" fluid/>
              </b-col>
              <b-col cols="12" md="10">
                <h5 class="mb-1">{{product.nom}}</h5>
                <h6>{{product.type}}</h6>
                <h6 v-if="classic">Nb Pièce Technique Max.: {{product.piece_technique}}</h6>
                <p>
                  {{product.designation}}
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>-->
    <b-row class="footer align-items-center no-gutters">
      <b-col md="2" cols="6" order="2" order-md="1" style="text-align:left;">
        <b-button @click="$router.go(-1)">
          <img :src='"/img/"+$path+"arrow_left_footer.png"'/>
        </b-button>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2" v-if="classic" class="content inner-shadow-box">
        <div class="content-block">
          <p>
            <strong>Les VMC sont dépendantes d’un nombre de pièces techniques et/ou d'un débit maximum.</strong><br>Au-delà <strong>contactez votre conseiller</strong>.
          </p>
        </div>
      </b-col>
      <b-col md="8" cols="12" order="1" order-md="2" v-if="classic_tertiaire" class="content inner-shadow-box">
        <div class="content-block">
          <p>
            <strong>Cet outil Estimair est limité à des configurations d'un débit maximum de 2000 m3/h</strong><br>Au-delà <strong>contactez le service Etudes pour un devis personalisé</strong>.
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'List',
  data() {
      return {
        show: true,
        selectedCategorie: 'Tous les produits',
        list_label: 'Choix VMC',
        classic: true,
        classic_tertiaire: true,
      }
    },
    methods: {
      selectVMC(vmc,event){
        event.preventDefault();
        if(!this.checkPieceTechnique(vmc)){
          this.$store.dispatch('devis/setVMC',vmc);
          this.$router.push('options');
        }
      },
      selectCategorie(categorie,ev){
        this.selectedCategorie = categorie;
        let x = document.getElementsByClassName("active");
        for(var i = 0; i < x.length; i++) {x[i].classList.remove('active');}
        ev.target.classList.toggle('active');
      },
      product_generate_img(lidic){
        var images = require.context('../assets/', false, /\.jpg/);
        return images('./'+lidic+'.jpg');
      },
      checkPieceTechnique(prod){
        let res = false;
        console.log(prod);
        console.log(prod.piece_technique);
        console.log((this.$store.state.devis.devis['request'].eau + this.$store.state.devis.devis['request'].sdb + this.$store.state.devis.devis['request'].sdb_wc + this.$store.state.devis.devis['request'].wc));
        console.log(prod.piece_technique < (this.$store.state.devis.devis['request'].eau + this.$store.state.devis.devis['request'].sdb + this.$store.state.devis.devis['request'].sdb_wc + this.$store.state.devis.devis['request'].wc));
        if(this.$store.state.devis.devis['type']=="tertiaire"){
          res = prod.debit_max_calcule > 2000;
        }else{
          if(prod.categorie!="Double Flux"){
            res = prod.piece_technique < (this.$store.state.devis.devis['request'].eau + this.$store.state.devis.devis['request'].sdb + this.$store.state.devis.devis['request'].sdb_wc + this.$store.state.devis.devis['request'].wc);
          }else{
            res = prod.debit_max < prod.debit_max_computed;
          }
        }
        return res;
      },
    },
    computed: {
      cssProps() {
          return {
            '--maincolor': this.$maincolor
          }
        },
      prev() {
        let res = '/'
        console.log("Back");
        switch(this.$store.state.devis.devis['type']){
          case "individuel":
            res = '/individuel';
          break;
          case 'collectif':
            res = '/collectif';
          break;
          case 'desenfumage':
            res = '/desenfumage';
          break;
          default:
          break;
        }
        return res;
      },
      products() {
        let products = [];
        this.$store.state.products.products.forEach((item) => {
          if(this.selectedCategorie=='Tous les produits' || item.categorie == this.selectedCategorie){
            products.push(item);
          }
        });
        return products;
      },
      categories() {
        let categ=['Tous les produits'];
        let products = this.$store.state.products.products;
        products.forEach((item) => {
          if(!categ.includes(item.categorie) && item.categorie!=undefined){
            categ.push(item.categorie);
          }
        });

        return categ;
      },
    },mounted(){
      window.scrollTo(0, 0);
      console.log(this.$store.state.products.products);
      console.log(this.$store.state.devis.devis);
      switch(this.$store.state.devis.devis['type']){
          case 'desenfumage':
            this.list_label = 'Choix Volet';
            this.classic = false;
            this.classic_tertiaire = false;
          break;
          case 'tertiaire':
            this.list_label = 'Choix VMC';
            this.classic = false;
          break;
          case 'collectif':
            this.list_label = 'Choix VMC';
            this.classic = false;
            this.classic_tertiaire = false;
          break;
          default:
            this.list_label = 'Choix VMC';
            this.classic = true;
            this.classic_tertiaire = false;
          break;
      }
    }
}
</script>

<style lang="scss">
#list{
  text-align: left;
  max-width: 1980px;
  margin: 2em auto;
  .filter button {
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    background-color: transparent !important;
    color:#333;
    border:0;
    text-transform: none;
    padding: 0.1em 1em;
    margin: 5px 12px;
    font-size: 1.75em;
    &.active{
      border:1px solid var(--maincolor);
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
    }
  }

  .product-item{
    @media (max-width: 576px) {
        margin: 2em 0px;
    }
    background: linear-gradient(-122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
    padding: 2em 2.5em;
    text-align: center;
    margin: 2em 30px;
    width: 100%;
    max-width: 400px;
    border-radius: 2em;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &.featured{
      outline: 10px solid var(--maincolor);

      h6{
        color: var(--maincolor);
        font-weight: bold;
        font-size: 1.3rem;
        text-transform: uppercase;

        img{
          width: 54px;
        }
      }
    }
    &.disabled{
      cursor: default;
      h5{
        color:#666;
      }
      p{
        color:#666;
      }
      h6{
        color: var(--maincolor);
      }
      &:before{
        content: " ";
        background: rgba(120, 120, 120, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 2em;
        z-index: 10;
      }
    }

    h5{
      color:black;
      font-size:2em;
      font-weight: bold;
    }
    img.vignette{
      width:80%;
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      border-radius:2em;
      padding: 0.5em 3em;
      background-color:white;
      margin: 1em auto;
    }

    h6{
      img{
        background-color: transparent;
        border-radius:0;
        box-shadow:none;
        padding:0;
        width:54px;
        margin-right: 1em;
      }

      span {
        z-index:12;
        position: relative;
      }
    }

    .button-next {
      background: linear-gradient(-70deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
      width:100px;
      height:100px;
      margin:0 auto;
      border-radius: 50%;
      margin-top: auto;
      img{
        transform: rotate(180deg);
        padding: 0.8em 2.2em;
      }
    }
  }
}
</style>
